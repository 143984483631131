import React from "react"
import { Typography, Container, Grid, Box, Link } from "@material-ui/core"
import { RoomOutlined } from "@material-ui/icons"

import LayoutComponent from "../../../../components/layout.component"

export default function ZencodeTechnologies() {
  return (
    <LayoutComponent>
      <Box pt={5}>
        <Container maxWidth="xl">
          <Grid container spacing={5} direction="row" alignItems="center">
            <Grid item md={6}>
              <img
                src="https://uxofeverything.com/static/fd74a92dae8e6a73d4734d968afcea89/6b64fc795f0d7a02d5a2345750cfea49.jpg"
                alt="cover"
                width="100%"
              />
            </Grid>
            <Grid item md={6}>
              <Box pb={2}>
                <Grid container justify="center" direction="row">
                  <Grid item>
                    <img
                      src="https://uxofeverything.com/strapi/uploads/c3_3ab6649463.jpg"
                      alt="logo"
                      width="250px"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                Zencode Technologies
              </Typography>
              <Typography gutterBottom variant="body1" component="p">
                Zencode Technologies is headquartered in Singapore but operates
                in 3 other cities- Chennai, Hong Kong, and Bangalore.
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Average rate: $25-$49 per hour
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Employees: 50-249
              </Typography>
              <Typography gutterBottom variant="body2" component="p">
                Website:{" "}
                <Link href="https://www.zencode.guru/" target="_blank">
                  https://www.zencode.guru/
                </Link>
              </Typography>
              <Box display="flex" flexDirection="row">
                <RoomOutlined fontSize="small" />
                <Typography variant="body2" component="p">
                  Address | III Floor, NCR Complex, 580/B, Sector 6, HSR Layout,
                  Bengaluru, Karnataka 560102
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box pt={5} pb={5}>
        <Container maxWidth="xl">
          <Grid container justify="center" direction="row">
            <Grid item>
              <Typography gutterBottom variant="h5" component="h2">
                About
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="center" direction="row">
            <Grid item md={10}>
              <Typography variant="body1" component="p">
                Zencode Technologies is headquartered in Singapore but operates
                in 3 other cities- Chennai, Hong Kong, and Bangalore. The team
                at Zencode Technologies specializes in using advanced
                technologies like big data, machine learning, and natural
                language processing. The team has worked with government
                agencies and MNCs and also has experience working in different
                sectors like food, healthcare, logistics, finance, and
                education. Zencode Technologies provides flexible digital
                solutions to businesses to adapt to market changes. They have
                worked on 250 plus projects and have developed 50 plus digital
                products. Their roster of clients includes Telstra, L'oreal,
                Ezlink, DBS, Expedia, Braintree, etc.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </LayoutComponent>
  )
}
